/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Router, Link, Route, Switch, HashRouter, Redirect } from "react-router-dom";
import axios from 'axios';
import Loader from './component/loader/home_loading'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { PARTNER_ID, GET_TOKEN } from './url';
import Header from './component/header/header';
import Footer from './component/footer/footer';
import PRIVACY from './container/pages/privacypolicy'

import './assests/css/bootstrap.min.css';
import './assests/css/owl.carousel.css';
import './assests/css/animate.css';
import './assests/css/font-awesome.min.css';
import './assests/css/player.css';
import './assests/css/login.css';
import './assests/css/responsive.css';
import './assests/css/staticpage.css';

// // import './assests/css/player_controls.css'
// // import './assests/css/playerNew.css'
import ProtectedRoute from './ProtectedRoute';
import publicroutes from './PublicRoutes.js';
import packageJson from '../package.json'
import { Helmet } from "react-helmet";

let url = window.location.href;
let pathname = new URL(url).pathname;
let lastIndex = pathname.lastIndexOf("/");
pathname = pathname.substring(0, lastIndex);
const pathArr = ["/transaction/invoice", "/mtransaction/invoice"];

class Result extends React.Component {
  state = { isLoading: false, token: '' }
  componentDidMount() {
    if (!pathArr.includes(pathname)) {
      // alert("didmount")
      import('./assests/css/style.css')
    } else {
      import('./assests/css/invoice.css')
    }
    //localStorage.setItem('token','ade46788d77e90ed7e532ce83873716e');
    //localStorage.setItem('userid',327);
    //this.getGeoLocation()
    this.caching()
    this.getToken()
    this.setState({ isLoading: true })
    //this.RmovPop();
  }
  componentWillMount() {
    if (!pathArr.includes(pathname)) {
      // alert("willmount")
      import('./assests/css/style.css')
    } else {
      import('./assests/css/invoice.css')
    }
  }

  caching = () => {
    // debugger
    let version = localStorage.getItem('version') !== null && localStorage.getItem('version') !== undefined && localStorage.getItem('version') !== '' ? localStorage.getItem('version') : ''
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name)
          })
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true)
      }
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  getToken = async () => {
    let tokenVal = localStorage.getItem('token')
    // let tokenVal = localStorage.getItem('token')
    let formData = new FormData();
    formData.append('partnerid', PARTNER_ID);
    if (!tokenVal) {
      await axios.get(GET_TOKEN, formData)
        .then(response => {
          let ins = this
          //debugger
          if (response.status === 200) {
            //debugger
            localStorage.setItem('token', response.data.Token)
            let apitoken = response.data.Token
            //window.location.reload()
            ins.setState({
              token: apitoken
            })

          }
        }).catch((error) => {
        })
    }
  }
  RmovPop = () => {
    let MenuRmv = document.querySelector('.drpdwn-dshbrd-wppr');
    MenuRmv.classList.remove("opn-menu")
  }
  render() {
    if (this.state.isLoading) {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Amrita Live</title>
          </Helmet>
          {!pathArr.includes(pathname) && <div id="snackbar" className="transition d-flex align-items-center">
            <span className="msg"></span>
            <Button className="ml-auto closeSnackBar">
              {/* <CloseIcon className="text-white" /> */}
            </Button>
          </div>}

          <BrowserRouter>
            {!pathArr.includes(pathname) && <Header {...this.props} token={this.state.token} />}
            <div className='mainWrapper'>
              <Switch>
                {/* <Route exact path="/" component={HOME} />
              <Route exact path="/activate" component={ACTIVATETV} />
              <Route exact path="/subscription" component={PLANLIST} />
              <Route exact path="/privacypolicy" component={PRIVACY} />
              <Route path='/checkout'
                render={(props) => (
                  <MainPage {...props} authed={true} />
                )}
              /> */}
                {/* FullpageRoutes */}

                {publicroutes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : null;
                },
                )}
                <Route path="/" component={App} />
                {/* <ProtectedRoute path="/" component={App} /> */}
              </Switch>
            </div>
            {!pathArr.includes(pathname) && <Footer token={this.state.token} />}
            {/* <Route path="/faqw" component={Footer} /> */}
          </BrowserRouter>
        </>
      )
    }
    else {
      return (<Loader />)
    }
  }
}
ReactDOM.render(<Result />, document.getElementById('root'));
serviceWorker.register();
